// what's the right interface for groups/sections

import {
  isArray,
  isArraysEqual,
  isNullOrEmpty,
  isString,
  joinClassNames,
} from "@common/lib/util"
import Loader from "@common/loader"
import SearchBar from "@common/searchBar"
import Svg from "@common/svg"
import EmptyResult from "@common/v2/dropdowns/emptyResult"
import OptionHeader from "@common/v2/dropdowns/optionHeader"
import { SelectOption } from "@common/v2/dropdowns/selectOption"
import {
  SelectOption as TSelectOption,
  SelectOptions,
} from "@common/v2/dropdowns/types"

import { filterOptions, getListedOptionClasses } from "@common/v2/dropdowns/utils"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react"
import { cva, VariantProps } from "class-variance-authority"
import { useEffect, useState } from "react"

type ListBoxBaseProps = {
  id?: string
  options?: SelectOptions
  disabled?: boolean
  enableSearch?: boolean
  placeholder?: string
  optionsWidth?: `w-${string}`
  enableReset?: boolean
}

type ListBoxProps<T extends boolean = false> = ListBoxBaseProps & {
  multiselect?: T
  value?: T extends true ? string[] : string
  onSelect?: (keys: T extends true ? string[] : string) => void
}

const ListBoxVariants = cva("flex w-[92%]", {
  variants: {
    size: {
      sm: "size-sm",
      xs: "size-xs",
    },
  },
  defaultVariants: {
    size: "xs",
  },
})

export interface ListBoxStyledProps<T extends boolean = false>
  extends ListBoxProps<T>,
    VariantProps<typeof ListBoxVariants> {}

export default function ListboxStyled<T extends boolean = false>({
  placeholder = "Select",
  options,
  onSelect,
  disabled,
  enableSearch,
  optionsWidth,
  enableReset,
  size,
  value,
  id,
  multiselect,
}: ListBoxStyledProps<T>) {
  const option =
    !multiselect && isString(value)
      ? options?.[value]
      : isArray(value)
        ? value.map((key) => options?.[key])
        : undefined

  const [selectedOptions, setSelectedOptions] = useState(option)
  const [query, setQuery] = useState<string>()

  useEffect(() => {
    if (isArray(selectedOptions)) {
      if (
        isArray(value) &&
        !isArraysEqual(selectedOptions?.map(({ key }) => key), value)
      ) {
        setSelectedOptions(option)
      }
    } else {
      if (value !== selectedOptions?.key && isString(value) && options) {
        setSelectedOptions(options[value])
      }
    }
  }, [options, value])

  const buttonClasses = joinClassNames(
    "bg-background text-foreground rounded-md border relative text-left cursor-pointer text-sm overflow-hidden disabled:cursor-not-allowed disabled:opacity-50 transition-colors delay-75 w-full hover:bg-muted",
    size == "sm" ? "text-xs" : "text-sm"
  )
  const listOptionsClasses = joinClassNames(
    optionsWidth ?? "w-[var(--button-width)]",
    "list-option z-50"
  )

  function handleChange(selected: TSelectOption[] | TSelectOption) {
    console.log("selected", selected)
    setSelectedOptions(selected)
    if (onSelect) {
      //@ts-ignore
      onSelect(isArray(selected) ? selected.map(({ key }) => key) : selected.key)
    }
  }

  const hasOptions = !isNullOrEmpty(options)
  const filteredOptions = filterOptions(options, query)
  const selectedOption = isArray(selectedOptions)
    ? selectedOptions.at(0)
    : selectedOptions

  return (
    <>
      <Listbox
        disabled={disabled}
        value={selectedOptions}
        onChange={handleChange}
        name={id}
        multiple={multiselect}
        by={(a, b) => a?.key === b?.key}
      >
        <ListboxButton
          onClick={() => setQuery(undefined)}
          className={buttonClasses}
          disabled={!hasOptions || disabled}
        >
          <div className={ListBoxVariants({ size })}>
            {!hasOptions && !selectedOptions && <Loader type="spinWithText" />}
            {!isNullOrEmpty(selectedOptions) ? (
              <>
                <SelectOption
                  display={selectedOption.display}
                  icon={selectedOption.icon}
                />
                {isArray(selectedOptions) && selectedOptions.length > 1 && (
                  <div className="py-[1px] px-1 border rounded-md shrink-0 text-xs">
                    +{selectedOptions.length - 1} More
                  </div>
                )}
              </>
            ) : (
              hasOptions && placeholder
            )}
          </div>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <Svg
              name="arrow-up-down"
              classes="h-4 w-4 text-secondary"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          className={listOptionsClasses}
          anchor="bottom start"
          transition
          modal={false}
        >
          {(enableSearch || (enableReset && value)) && (
            <div className="sticky top-0 m-2 space-y-1 text-center backdrop-blur-sm bg-background z-20">
              {enableSearch && (
                <div className="rounded-md border bg-background px-2">
                  <SearchBar onChange={(text) => setQuery(text)} />
                </div>
              )}

              {enableReset && selectedOptions && (
                <button
                  onClick={() => {
                    if (onSelect) onSelect(undefined)
                    setSelectedOptions([])
                    setQuery(undefined)
                  }}
                  className="text-secondary border rounded-md w-full px-2 py-1 text-xs disabled:opacity-50"
                >
                  clear selection
                </button>
              )}
            </div>
          )}

          {isNullOrEmpty(filteredOptions) ? (
            <EmptyResult />
          ) : (
            Object.keys(filteredOptions).map((key) => {
              const option = filteredOptions[key]
              if (option.options) {
                return (
                  <div key={key}>
                    <OptionHeader display={option.display} />
                    {Object.keys(option.options).map((subKey) => {
                      const subOption = option.options[subKey]
                      return renderListboxOption(subOption)
                    })}
                  </div>
                )
              }
              return renderListboxOption(option)
            })
          )}
        </ListboxOptions>
      </Listbox>
    </>
  )

  function renderListboxOption(option: TSelectOption) {
    return (
      <ListboxOption
        key={option.key}
        value={option}
        disabled={option.disabled}
        className={({ focus }) =>
          getListedOptionClasses(focus, option.disabled || disabled, size)
        }
      >
        {({ selected }) => (
          <SelectOption
            icon={option.icon}
            display={option.display}
            selected={selected}
            showCheckbox={multiselect}
          />
        )}
      </ListboxOption>
    )
  }
}
